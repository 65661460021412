import Link from 'next/link'
import { OTokenSpinner } from '../OTokenSpinner'
import { Button } from '../shared/Button'

export const TradeOptions = () => {
  return (
    <>
    <h2 className="font-parabole text-center mb-16">Dynamic Hedging Vault (DHV)</h2>
    <div className="flex flex-col md:flex-row mb-24 justify-between items-center px-8 md:px-48">
      <div className="w-full md:w-[50%]">
        <h3 className="mb-8 mt-8">Trade on-chain options with ease</h3>
        <ul className="mb-8 list-disc list-inside" >
          <li className='pb-4'>Tight bid and ask spreads</li>
          <li className='pb-4'>Capital Efficient with deep concentrated liquidity</li>
          <li className='pb-4'>One-click options strategies construction</li>
          <li className='pb-4'>Fast and cheap on Arbitrum</li>
        </ul>
        <Link href="https://app.rysk.finance/" target="_self" rel="noreferrer">
          <Button className="!py-4 !px-8">Trade Options</Button>
        </Link>
      </div>
      <div className="rounded-lg w-full md:w-[400px]">
        <div className="rounded-t-lg bg-black text-white flex justify-center py-4 mt-16 md:mt-0">
          <p>DynamicHedging.gif</p>
        </div>
        <div>
          <div className="border-black border-2 rounded-b-lg p-8 overflow-hidden h-[500px] bg-bone">
            <OTokenSpinner />
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
