import type { NextPage } from 'next'
import { useCallback, useState } from 'react'

import { GLSLCanvas } from '../components/GLSLCanvas'
import { HedgingVault } from '../components/sections/HedgingVault'
import { Hero } from '../components/sections/Hero'
import { Integrations } from '../components/sections/Integrations'
import { Security } from '../components/sections/Security'
import { TradeOptions } from '../components/sections/TradeOptions'
import { Footer } from '../components/shared/Footer'

const Home: NextPage = () => {
  const [scrollTop, setScrollTop] = useState(0)
  const [scrollContainerHeight, setScrollContainerHeight] = useState(0)
  const [windowHeight, setWindowHeight] = useState(0)

  const getWindowHeight = useCallback(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  const getScrollContainerHeight = useCallback((element: HTMLDivElement) => {
    setScrollContainerHeight(element.scrollHeight)
  }, [])

  const getScrollPosition = (element: HTMLDivElement) => {
    const scrollProportion = scrollContainerHeight
      ? Math.min(1.0, element.scrollTop / (scrollContainerHeight - windowHeight))
      : 0
    setScrollTop(scrollProportion)
  }

  const scrollContainerRef = (element: HTMLDivElement | null) => {
    element?.addEventListener('scroll', (event) => {
      if (event.target) {
        const target = event.target as HTMLDivElement
        getScrollPosition(target)
      }
    })

    window?.addEventListener('resize', () => {
      if (element) {
        getScrollContainerHeight(element)
      }
    })

    getWindowHeight()
    element && getScrollContainerHeight(element)
    element && getScrollPosition(element)
  }

  return (
    <div className="mt-24 relative">
      <div className="flex justify-start items-center relative">
        {<GLSLCanvas containerClass="h-[calc(100vh-96px)] w-full" scrollTop={scrollTop} />}
      </div>
      <div className="absolute h-full w-full top-0 overflow-y-auto" ref={scrollContainerRef}>
        <Hero />
        <TradeOptions />
        <HedgingVault />
        <Integrations />
        <Security />
        <Footer />
      </div>
    </div>
  )
}

export default Home
