import Link from 'next/link'
import { Button } from '../shared/Button'

export const HedgingVault = () => {
  return (
    <div>
      <div className="flex flex-col md:flex-row mb-24 justify-between items-center px-8 md:px-48">
        <div className="rounded-lg w-full md:w-[400px]">
          <div className="rounded-t-lg bg-black text-white flex justify-center py-4 mt-16 md:mt-0">
            <p>DynamicHedging.gif</p>
          </div>
          <div>
            <div className="border-black border-2 rounded-b-lg p-8 overflow-hidden h-[500px] bg-bone">
              <img src={'/bento.png'} className="mb-4 animate-vertical-scroll" />
              <img src={'/bento.png'} className="mb-4 animate-vertical-scroll" />
              <img src={'/bento.png'} className="mb-4 animate-vertical-scroll" />
            </div>
          </div>
        </div>
        <div className="w-full md:w-[50%]">
          <h3 className="mb-8">Earn uncorrelated returns</h3>
          <ul className="mb-8 list-disc list-inside" >
            <li className='pb-4'>Automatically earn sustainable market-neutral returns for better crypto portfolio diversification</li>
            <li className='pb-4'>Dynamically hedged to minimise market exposure and reduce directionality</li>
          </ul>
          <Link href="https://app.rysk.finance/vault" target="_self" rel="noreferrer">
            <Button className="!py-4 !px-8">Earn Uncorrelated Returns</Button>
          </Link>
        </div>

      </div>
    </div>
  )
}
