export const Security = () => {
  return (
    <div className="md:px-48 px-8 py-16">
      <h2 className="text-center font-parabole mb-16">Security</h2>
      <div className="w-full flex md:flex-row flex-col justify-between mb-16">
        <div className="rounded-lg border-2 border-black md:w-[45%] w-full md:mb-0 mb-8 bg-bone">
          <div className="flex w-full text-white bg-black justify-center py-2">
            <p>Dedaub</p>
          </div>
          <div className="p-4 flex flex-col items-center">
            <img src={'/logos/dedaub.svg'} className="my-8 max-h-[80px]" />
            <p className="text-center">
              We asked Dedaub to audit the Rysk smart contracts. Dedaub has strong expertise with DeFi derivative
              protocols.
            </p>
            <a
              href="https://docs.rysk.finance/security/security-reviews#dedaub-june-2022"
              className="block underline pt-4"
              target="_blank"
              rel="noreferrer"
            >
              The report can be found here
            </a>
          </div>
        </div>

        <div className="rounded-lg border-2 border-black md:w-[45%] w-full md:mb-0 mb-8 bg-bone">
          <div className="flex w-full text-white bg-black justify-center py-2">
            <p>Trust</p>
          </div>
          <div className="p-4 flex flex-col items-center">
            <img src={'/logos/trust.svg'} className="mb-8 max-h-[80px]" />
            <p className="text-center">
              We asked Trust to audit the Rysk Beyond smart contracts, considering their experience in the cybersecurity space.
            </p>
            <a
              href="https://www.trust-security.xyz/rysk-audit"
              className="block underline pt-4"
              target="_blank"
              rel="noreferrer"
            >
              The report can be found here
            </a>
          </div>
        </div>
      </div>
      <div className="w-full flex md:flex-row flex-col justify-between mb-16">

        <div className="rounded-lg border-2 border-black md:w-[45%] w-full md:mb-0 mb-8 bg-bone">
          <div className="flex w-full text-white bg-black justify-center py-2">
            <p>Akira</p>
          </div>
          <div className="p-4 flex flex-col items-center">
            <img src={'/logos/akira.svg'} className="mb-8 max-h-[80px]" />
            <p className="text-center">
              We asked Akira to audit the Rysk DHV Alpha contracts and the modifications we made to the Opyn Gamma Smart
              Contracts.
            </p>
            <a
              href="https://docs.rysk.finance/security/security-reviews#akiratech-august-2022"
              className="block underline pt-4"
              target="_blank"
              rel="noreferrer"
            >
              The report can be found here
            </a>
          </div>
        </div>

        <div className="rounded-lg border-2 border-black md:w-[45%] w-full md:mb-0 mb-8 bg-bone">
          <div className="flex w-full text-white bg-black justify-center py-2">
            <p>Peckshield</p>
          </div>
          <div className="p-4 flex flex-col items-center">
            <img src={'/logos/peckshield.svg'} className="mb-8 max-h-[80px]" />
            <p className="text-center">
              We asked Peckshield to audit modifications we made to the Opyn Gamma Smart Contracts.
            </p>
            <a
              href="https://docs.rysk.finance/security/security-reviews#peckshield-may-2022"
              className="block underline pt-4"
              target="_blank"
              rel="noreferrer"
            >
              The report can be found here
            </a>
          </div>
        </div>

      </div>
      <div className="w-full flex md:flex-row flex-col justify-between mb-16">

        <div className="rounded-lg border-2 border-black md:w-[45%] w-full md:mb-0 mb-8 bg-bone">
          <div className="flex w-full text-white bg-black justify-center py-2">
            <p>Tenderly</p>
          </div>
          <div className="p-4 flex flex-col items-center">
            <img src={'/logos/tenderly.svg'} className="my-8 max-h-[80px]" />
            <p className="text-center">
              We use Tenderly for real-time monitoring, alerting, and web3 actions to keep the protocol healthy
            </p>
          </div>
        </div>

        <div className="rounded-lg border-2 border-black md:w-[45%] w-full md:mb-0 mb-8 bg-bone">
          <div className="flex w-full text-white bg-black justify-center py-2">
            <p>Immunefi</p>
          </div>
          <div className="p-4 flex flex-col items-center">
            <img src={'/logos/immunefi.svg'} className="mb-8 p-3 max-h-[80px]" />
            <p className="text-center">
              We have a bug bounty in place with Immunefi. Help us secure Rysk and earn a bounty!
            </p>
            <a
              href="https://immunefi.com/bounty/rysk/"
              className="block underline pt-4"
              target="_blank"
              rel="noreferrer"
            >
              Check it out
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
