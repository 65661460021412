import { Box, useGLTF } from '@react-three/drei'
import { Canvas, useFrame } from '@react-three/fiber'
import { useMemo, useRef, useState } from 'react'
import THREE, { BufferGeometry, Material, Mesh, MeshStandardMaterial } from 'three'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'

type GLTFTokenResult = GLTF & {
  nodes: {
    Token: THREE.Mesh
  }
}

const OTokenSpinnerContent = () => {
  const logoRef = useRef<Mesh<BufferGeometry, Material | Material[]> | null>(null)
  const { nodes } = useGLTF('/token.glb') as unknown as GLTFTokenResult
  const [mouseIsOver, setMouseIsOver] = useState(false)

  const wireframeMaterial = useMemo(() => {
    return new MeshStandardMaterial({ wireframe: true, wireframeLinewidth: 20 })
  }, [])

  useFrame(() => {
    if (logoRef.current) {
      logoRef.current.rotateZ(mouseIsOver ? -0.03 : 0.01)
    }
  })

  return (
    <>
      <mesh
        ref={logoRef}
        material={wireframeMaterial}
        position={[0, 0, 0]}
        geometry={nodes.Token.geometry}
        rotation={[Math.PI / 1.7, 0, 0]}
        scale={2.5}
      />
      {/* The hitbox for the mouse interaction */}
      <Box
        scale={3}
        onPointerEnter={() => setMouseIsOver(true)}
        onPointerLeave={() => setMouseIsOver(false)}
        visible={false}
      />
    </>
  )
}

export const OTokenSpinner = () => {
  return (
    <Canvas orthographic camera={{ zoom: 60 }}>
      {<OTokenSpinnerContent />}
    </Canvas>
  )
}
