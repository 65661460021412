import { useCallback, useEffect, useState } from 'react'

const ARBITRUM_LINK = 'https://arbitrum.io/'
const RAGE_TRADE_LINK = 'https://www.rage.trade/'
const UNISWAP_LINK = 'https://uniswap.org/'
const OPYN_LINK = 'https://www.opyn.co/'
const GMX_LINK = 'https://gmx.io/'
const CHAINLINK_LINK = 'https://chain.link/'
const GELATO_LINK = 'https://www.gelato.network/blog/rysk-finance-gelato-web3-functions'

export const Integrations = () => {
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null)
  const [rect, setRect] = useState<DOMRect | null>(null)

  const setContainerRect = useCallback(() => {
    if (containerElement) {
      setRect(containerElement.getBoundingClientRect())
    }
  }, [containerElement])

  const containerRef = (element: HTMLDivElement | null) => {
    if (element && !rect) {
      setContainerElement(element)
      setContainerRect()
    }
  }

  useEffect(() => {
    window.addEventListener('resize', setContainerRect)

    return () => {
      window.removeEventListener('resize', setContainerRect)
    }
  }, [setContainerRect])

  useEffect(() => {
    if (containerElement && rect && !rect.height) {
      setRect(containerElement.getBoundingClientRect())
    }
  }, [containerElement, rect])

  const isMobile = rect && rect.width < 200

  return (
    <div className="w-full bg-black py-32">
      <h2 className="font-parabole text-white text-center mb-16">Integrations</h2>
      <div className="flex justify-center">
        <div className="w-[40%] relative" ref={containerRef}>
          <img src="/bento_invert.png" />
          <div className="absolute w-full h-full top-0 left-0">
            {rect && rect.height && (
              <div className="w-full h-full relative">
                <div
                  className={`absolute flex`}
                  style={{ top: rect.height * 0.1, right: rect.width - (isMobile ? 40 : 100) }}
                >
                  <a
                    className="bg-white md:p-4 p-2 md:pr-4 pr-3 flex items-center"
                    href={ARBITRUM_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="mr-2">Arbitrum</p>
                    <img src={'/icons/link-out.svg'} />
                  </a>
                  <div
                    className="border-b-2 border-white"
                    style={{ width: rect.width * (rect.width > 200 ? 0.5 : 0.2) }}
                  />
                </div>

                <div
                  className={`absolute flex`}
                  style={{ top: rect.height * 0.4, right: rect.width - (isMobile ? 40 : 100) }}
                >
                  <a
                    className="bg-white md:p-4 p-2 md:pr-4 pr-3 flex items-center"
                    href={RAGE_TRADE_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="mr-2">RageTrade</p>
                    <img src={'/icons/link-out.svg'} />
                  </a>
                  <div
                    className="border-b-2 border-white"
                    style={{ width: rect.width * (rect.width > 200 ? 0.5 : 0.2) }}
                  />
                </div>

                <div
                  className={`absolute flex`}
                  style={{ top: rect.height * 0.7, right: rect.width - (isMobile ? 20 : 100) }}
                >
                  <a
                    className="bg-white md:p-4 p-2 md:pr-4 pr-3 flex items-center"
                    href={UNISWAP_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="mr-2">Uniswap</p>
                    <img src={'/icons/link-out.svg'} />
                  </a>
                  <div
                    className="border-b-2 border-white"
                    style={{ width: rect.width * (rect.width > 200 ? 0.5 : 0.2) }}
                  />
                </div>

                <div
                  className={`absolute flex`}
                  style={{ top: rect.height * 0, left: rect.width - (isMobile ? 20 : 100) }}
                >
                  <div
                    className="border-b-2 border-white"
                    style={{ width: rect.width * (rect.width > 200 ? 0.5 : 0.2) }}
                  />
                  <a
                    className="bg-white md:p-4 p-2 md:pr-4 pr-3 flex items-center"
                    href={GMX_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="mr-2">GMX</p>
                    <img src={'/icons/link-out.svg'} />
                  </a>
                </div>

                <div
                  className={`absolute flex`}
                  style={{ top: rect.height * 0.25, left: rect.width - (isMobile ? 20 : 100) }}
                >
                  <div
                    className="border-b-2 border-white"
                    style={{ width: rect.width * (rect.width > 200 ? 0.5 : 0.2) }}
                  />
                  <a
                    className="bg-white md:p-4 p-2 md:pr-4 pr-3 flex items-center"
                    href={OPYN_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="mr-2">Opyn</p>
                    <img src={'/icons/link-out.svg'} />
                  </a>
                </div>

                <div
                  className={`absolute flex`}
                  style={{ top: rect.height * 0.5, left: rect.width - (isMobile ? 20 : 100) }}
                >
                  <div
                    className="border-b-2 border-white"
                    style={{ width: rect.width * (rect.width > 200 ? 0.5 : 0.2) }}
                  />
                  <a
                    className="bg-white md:p-4 p-2 md:pr-4 pr-3 flex items-center"
                    href={CHAINLINK_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="mr-2">Chainlink</p>
                    <img src={'/icons/link-out.svg'} />
                  </a>
                </div>

                <div
                  className={`absolute flex`}
                  style={{ top: rect.height * 0.8, left: rect.width - (isMobile ? 20 : 100) }}
                >
                  <div
                    className="border-b-2 border-white"
                    style={{ width: rect.width * (rect.width > 200 ? 0.5 : 0.2) }}
                  />
                  <a
                    className="bg-white md:p-4 p-2 md:pr-4 pr-3 flex items-center"
                    href={GELATO_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="mr-2">Gelato</p>
                    <img src={'/icons/link-out.svg'} />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
