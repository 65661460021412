import { GLSL, Node, Shaders } from 'gl-react'
import { Surface } from 'gl-react-dom'
import { useCallback, useEffect, useState } from 'react'

// in gl-react you need to statically define "shaders":
const shaders = Shaders.create({
  helloGL: {
    frag: GLSL`
      #define PI 3.1415926538

      precision highp float;
      varying vec2 uv;
      uniform vec2 resolution;
      uniform float time;
      uniform sampler2D noiseTexture;
      uniform float scrollTop;
      uniform vec2 center;
      uniform vec2 distortionScale;
      uniform float blobClosingProportion;

      float blob1Progress = min(blobClosingProportion, scrollTop) * 1.0 / blobClosingProportion;


      vec4 createGrid(in vec2 uv) {
        float gridSize = resolution.x > 1024.0 ? 20.0 : 10.0;
        float lineWidthProportion = 0.05;
        float squareSize = 1.0 / gridSize;

        float width = (gridSize) / resolution.x;

        float aspectRatio = resolution.x / resolution.y;

        vec2 uvFract = fract(vec2(uv.x, uv.y / aspectRatio) * gridSize);

        float lineWidth = resolution.x / gridSize;

        // abs version
        float grid = max(
              width - (uvFract.y) / width,
              width - (uvFract.x) / width
        ) / lineWidth * -1.0;
        
        if (grid < lineWidthProportion && (uv.x > squareSize) && (uv.x < (1.0 - squareSize + squareSize * (lineWidthProportion)))) {
            return vec4(vec3(1.0), 1.0);
        } else {
            return vec4(0.9294, 0.9137, 0.8667, 1.);
        }
      }

      void main() {
        // params
        float blobSize = 0.10 * (1. - blob1Progress);
        float blobDistortionAmount = 5.;
        float distortionFalloff = 0.20;
        float anotherDistortionFalloff = 0.82;


        float timeDistort = time / (50. * 1000.);
        float timeRotate = time / (500. * 1000.);
        float sinTime = fract(timeDistort);

        vec2 up = vec2(0., 1.);

        float dist = length((uv - center) / distortionScale);

        vec2 radial = normalize(uv - center);
        float angle = fract((atan(radial.x, radial.y) + PI) / (2. * PI) + timeRotate);

        vec2 noiseTextureSamplePosition = vec2(angle, sinTime);
        vec4 noiseColor = texture2D(noiseTexture, noiseTextureSamplePosition); 

        float blob = (1. - float(dist < (blobSize + (noiseColor.r / blobDistortionAmount * (1.0 - blob1Progress)))));

        // change final smoothstep param to change widht of distorted positions.
        float ring = smoothstep(1. - (dist - noiseColor.r / blobDistortionAmount), 0., anotherDistortionFalloff) * 20.;
      
        // change final value here to the distortion falloff. 
        float halo = ring * blob * blobSize * 2.0;

        vec2 gridSamplePoint = vec2(uv + normalize(center - uv) * halo);

        vec4 grid = createGrid(gridSamplePoint);

        if (blob == 0.) {
            gl_FragColor = vec4(0.0);
        } else {
            gl_FragColor = vec4(grid);
        }

        // gl_FragColor = vec4(halo);
      }
    `,
  },
})

type GLSLCanvasProps = {
  containerClass?: string
  scrollTop: number
}

export const GLSLCanvas = ({ containerClass, scrollTop }: GLSLCanvasProps) => {
  const [containerRect, setContainerRect] = useState<DOMRect | null>(null)
  const [loading, setLoading] = useState(true)
  const [time, setTime] = useState<number>(0)

  const getContainerSize = useCallback((container: HTMLDivElement) => {
    const rect = container.getBoundingClientRect()
    setContainerRect(rect)
  }, [])

  const containerRef = useCallback((container: HTMLDivElement | null) => {
    if (container) {
      window.addEventListener('resize', () => getContainerSize(container))
      getContainerSize(container)
    }
  }, [])

  // Setup timing uniform
  useEffect(() => {
    const loop = (time: number) => {
      requestAnimationFrame(loop)
      setTime(time)
    }
    requestAnimationFrame(loop)
  }, [])

  // Shader params that depend on screen size.
  // 768 is tablet width.
  const screenDependentShaderParams =
    containerRect?.width && containerRect.width < 768
      ? {
          distortionCenter: [0.52, 0.8],
          distortionScale: [2.5, 1.0],
          blobClosingProportion: 0.05,
        }
      : {
          distortionCenter: [0.74, 0.5],
          distortionScale: [1.0, 1.8],
          blobClosingProportion: 0.15,
        }

  return (
    <>
      {!loading && (
        <div
          className={`absolute h-full w-full md:w-[60%] left-0 md:left-[40%] bg-cover md:bg-center bg-[center_top_-10rem]`}
          style={{ backgroundImage: `url(/rysk_wall_street.jpg)` }}
        />
      )}
      <div className={containerClass} ref={containerRef}>
        {containerRect && (
          <Surface width={containerRect.width} height={containerRect.height}>
            <Node
              shader={shaders.helloGL}
              uniforms={{
                time,
                noiseTexture: '/noise.png',
                resolution: [containerRect.width, containerRect.height],
                scrollTop,
                center: screenDependentShaderParams.distortionCenter,
                distortionScale: screenDependentShaderParams.distortionScale,
                blobClosingProportion: screenDependentShaderParams.blobClosingProportion,
              }}
              onDraw={() => {
                if (loading) {
                  setLoading(false)
                }
              }}
            />
          </Surface>
        )}
      </div>
    </>
  )
}
