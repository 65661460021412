import Link from 'next/link'
import { Button } from '../shared/Button'

export const Hero = () => {
  return (
    <div className="w-full md:h-full h-fit  flex justify-between md:items-center mt-64 md:mt-0 mb-[200px] px-8 md:px-48">
      <div className="w-full md:w-[50%]">
        <h1 className="font-parabole text-[48px] md:text-[80px] md:leading-[96px] mb-8">
          <>Options trading, < br />simplified.</>
        </h1>
        <h2 className="text-[18px] md:text-[24px] mb-8">
          <>Decentralized options protocol to trade volatility and directionality with leverage, build 1-click strategies & earn uncorrelated returns.</>
        </h2>
        <Link href="https://app.rysk.finance/" target="_self" rel="noreferrer">
          <Button className="!py-4 !px-8">Start Trading</Button>
        </Link>
      </div>
    </div>
  )
}
